<template>
  <v-container fluid class="text-justify">
    <v-row no-gutters justify="center">
      <v-col md="8">
        <h2 class="text-h6 text-center mb-5">Hinweis zum Datenschutz</h2>
        <p>
          Die Daten werden auf der Grundlage von § 4 DSG NRW, Art.6 DSGVO verarbeitet und nur solange gespeichert, wie
          es f&uuml;r den jeweiligen Zweck der Anwendung erforderlich ist und nicht an Dritte weitergegeben. Im Zuge der
          Weiterentwicklung des Internetangebotes k&ouml;nnen auch &Auml;nderungen dieser Datenschutzerkl&auml;rung
          erforderlich werden. Wir empfehlen Ihnen deshalb, sich die Datenschutzerkl&auml;rung von Zeit zu Zeit erneut
          durchzulesen.
        </p>
        <h3 class="text-subtitle-1 font-weight-bold mb-2">Automatische Speicherung von Daten</h3>
        <p>
          Bei jedem Zugriff auf Inhalte des Internetangebotes werden auf der Grundlage von § 100 Absatz 1 TKG, § 15 TMG
          vor&uuml;bergehend Daten &uuml;ber so genannte Protokoll- oder Log-Dateien auf den Servern des Dienstes
          gespeichert, die m&ouml;glicherweise eine Identifizierung zulassen. Die folgenden Daten werden bei jedem
          Aufruf der Internetseite erhoben:
        </p>
        <ul class="mb-4">
          <li>Datum und Uhrzeit des Abrufs</li>
          <li>Name des aufgerufenen Internetdienstes</li>
          <li>Name der aufgerufenen Ressource</li>
          <li>Name der verwendeten Aktion</li>
          <li>Abfrage, die der Client gestellt hat</li>
          <li>&uuml;bertragene Datenmenge</li>
          <li>Meldung, ob der Abruf erfolgreich war</li>
          <li>IP-Adresse des aufrufenden Rechners</li>
          <li>Clientinformationen (Browser, Betriebssystem)</li>
        </ul>
        <p>
          Diese Daten aus den Protokoll- bzw. Logdateien der Server dienen zur Abwehr und Analyse von Angriffen auf das
          Portal und werden bis zu 5 Tage direkt und ausschließlich f&uuml;r Administratoren zug&auml;nglich aufbewahrt.
          Danach sind sie nur noch indirekt &uuml;ber die Rekonstruktion von Sicherungsb&auml;ndern verf&uuml;gbar und
          werden nach sechs Wochen endg&uuml;ltig gel&ouml;scht.
        </p>
        <p>
          Bei jedem Zugriff eines Nutzers auf die Geodatenbereitstellung und bei jedem Download einer Datei werden
          folgende Daten &uuml;ber diesen Vorgang anonymisiert gespeichert und verarbeitet:
        </p>
        <ul class="mb-4">
          <li>Summe der Aufrufe der Internetseite / Tag</li>
          <li>Summe der Downloads / Tag</li>
        </ul>
        <p>
          Diese Summen werden nur statistisch sowie zur Optimierung des Internetangebotes ausgewertet und ca. ein Jahr
          lang gespeichert. Es werden keine Nutzerprofile erstellt.
        </p>
        <h3>Weitergabe personenbezogener Daten</h3>
        <p>
          Personenbezogene Daten sowie die besonderen Daten (§ 3 Abs. 9 BDSG, z. B. politische Meinungen) werden nur
          dann gespeichert, wenn Sie uns ausdr&uuml;cklich und wissentlich solche Informationen f&uuml;r bestimmte
          Zwecke, Dienste und Funktionen zur Verf&uuml;gung stellen. Dies betrifft:
        </p>

        <p>Anfragen via E-Mail oder Telefon</p>
        <ul class="mb-4">
          <li>Vor- und Nachname</li>
          <li>Wohnort bzw. Dienststelle</li>
          <li>E-Mail-Adresse bzw. Telefonnummer</li>
          <li>Text</li>
        </ul>

        <p>Nutzerinnen- bzw. Nutzerprofil</p>
        <ul>
          <li>Vor- und Nachname</li>
          <li>Adresse</li>
          <li>E-Mail-Adresse</li>
          <li>Organisation</li>
          <li>Abteilung</li>
          <li>Telefon</li>
        </ul>

        <p>
          Die von Ihnen eingegebenen pers&ouml;nlichen Daten verwenden wir nur zu dem/der von Ihnen gew&uuml;nschten
          Zweck, Dienst oder Funktion und nur innerhalb IT.NRW bzw. der mit dem jeweiligen Service beauftragten
          Beh&ouml;rden, Dienststellen und Institutionen. Eine Weitergabe personenbezogener Daten erfolgt nicht.
        </p>

        <h3 class="text-subtitle-1 font-weight-bold mb-2">Cookies</h3>
        <p>
          Die Geodatenbereitstellung verwendet lediglich Session Cookies mit einem Sitzungsbezeichner (Session-ID).
          Diese Form des Cookies speichert keinen Hinweis, der der Identifikation des Nutzers dient und wird
          gel&ouml;scht, sobald der Nutzer nach seiner Sitzung (englisch: Session) den Browser schließt. Session Cookies
          und ihre Nutzung m&uuml;ssen in der Regel bei Besuch einer Seite nicht erbeten werden. Sie k&ouml;nnen
          automatisch im Hintergrund laufen und fallen nicht unter die Opt-In-Regel, nach der eine Zustimmung des
          Nutzers notwendig w&auml;re.
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {};
</script>
